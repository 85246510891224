<template>
    <div>
        <b-row class="pricing-card">
            <b-col>
                <b-card>
                    <b-row>
                        <b-col md="12" cols="12" class="text-center">
                            <h1 class="m-1">Pricing Plans</h1>
                            <p>
                                You have a voucher?
                                <b-link :to="{ name: 'voucher' }"
                                    >Redeem a voucher</b-link
                                >
                                or
                                <b-link :to="{ name: 'resellers' }"
                                    >Check our resellers</b-link
                                >
                            </p>
                            <p>
                                Follow us on Telegram for the latest news,
                                discount code and more:
                                <a
                                    href="https://t.me/leechallcom"
                                    target="_blank"
                                    >https://t.me/leechallcom</a
                                >
                            </p></b-col
                        >

                        <b-col md="6" cols="12">
                            <b-card class="popular">
                                <b-list-group
                                    class="list-group-circle text-left"
                                >
                                    <b-list-group-item
                                        >60+ Filehosts</b-list-group-item
                                    >
                                    <b-list-group-item
                                        >200 GB Daily
                                        Bandwidth</b-list-group-item
                                    >
                                    <b-list-group-item
                                        >High Speed Download</b-list-group-item
                                    >
                                </b-list-group>
                            </b-card></b-col
                        ><b-col md="6" cols="12">
                            <b-card class="popular">
                                <b-list-group
                                    class="list-group-circle text-left"
                                >
                                    <b-list-group-item
                                        >Secure & Anonymous</b-list-group-item
                                    >
                                    <b-list-group-item
                                        >24/7 Customer
                                        Support</b-list-group-item
                                    >
                                    <b-list-group-item
                                        >No Ads - No Waiting
                                        Time</b-list-group-item
                                    >
                                </b-list-group>
                            </b-card>
                        </b-col>
                        <b-col md="12" cols="12">
                            <b-card
                                border-variant="danger"
                                title="NOTE - MAKE SURE YOU READ IT BEFORE MAKING ANY PAYMENT !!!"
                            >
                                <b-list-group
                                    class="list-group-circle text-left"
                                >
                                    <b-list-group-item
                                        >Please check host status page before
                                        making any payment.</b-list-group-item
                                    >
                                    <b-list-group-item
                                        >Check Daily size limits and Bandwidth
                                        usage limits.</b-list-group-item
                                    >
                                    <b-list-group-item
                                        >Filesize and Bandwidth limits can alter
                                        anytime without any notice (increase or
                                        decrease).</b-list-group-item
                                    >
                                    <b-list-group-item
                                        >Once premium key is applied
                                        successfully then it can't be revoked.
                                        Also no refunds are
                                        possible.</b-list-group-item
                                    >
                                    <b-list-group-item>
                                        Some hosts like Tezfiles, Filesfly,
                                        Elitefile... can not download all files.
                                        Because there are some files need pay or
                                        separate subscription for sites that
                                        upload files to download it.
                                    </b-list-group-item>
                                    <b-list-group-item>
                                        <strong>
                                            PLEASE CONTACT US VIA
                                            <a
                                                href="https://t.me/ndt258"
                                                target="_blank"
                                                >Telegram</a
                                            >
                                            OR
                                            <a href="mailto:support@leechall.io"
                                                >support@leechall.io</a
                                            >
                                            IF YOU HAVE ANY QUESTIONS BEFORE
                                            MAKING PAYMENT.</strong
                                        >
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="4" v-for="item in items" :key="item.id">
                            <b-card align="center" border-variant="primary">
                                <h3>{{ item.name }}</h3>
                                <div class="annual-plan border-top">
                                    <div class="plan-price mt-2">
                                        <sup
                                            class="font-medium-1 font-weight-bold text-primary"
                                            >{{ toSymbols(item.currency) }}</sup
                                        >
                                        <span
                                            class="pricing-basic-value font-weight-bolder text-primary"
                                            >{{ item.price }}</span
                                        >
                                    </div>
                                </div>
                                <!-- buttons -->
                                <b-button
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    block
                                    class="mt-2"
                                    variant="outline-primary"
                                    @click="onPurchase(item.id)"
                                >
                                    Purchase
                                </b-button>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <!-- Modal show payments -->
        <b-modal
            v-model="show"
            hide-footer
            title="Please choose payment method"
        >
            <b-list-group flush class="mb-1">
                <b-list-group-item
                    button
                    v-for="item in payments"
                    :key="item.id"
                    @click="onPayment(item.id)"
                >
                    <b-img
                        :src="iconPayment(item.logo)"
                        class="mr-1 payment-icon"
                    />
                    <p class="float-left">
                        <strong class="d-block">{{ item.name }}</strong>
                        <small>{{ item.description }}</small>
                    </p>
                </b-list-group-item>
            </b-list-group>
            <small
                >Can't find any suitable payment method?
                <b-link :to="{ name: 'resellers' }">Check our resellers</b-link>
                or contact us.</small
            >
        </b-modal>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
    BLink,
    BModal,
    VBModal,
    BAvatar,
    BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BListGroup,
        BListGroupItem,
        BButton,
        BLink,
        BModal,
        BAvatar,
        BImg,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    created() {
        this.fetchPlans();
        this.fetchPayments();
    },
    computed: {
        toSymbols() {
            return (currency) => {
                var currency_symbols = {
                    USD: "$", // US Dollar
                    EUR: "€", // Euro
                    CRC: "₡", // Costa Rican Colón
                    GBP: "£", // British Pound Sterling
                    ILS: "₪", // Israeli New Sheqel
                    INR: "₹", // Indian Rupee
                    JPY: "¥", // Japanese Yen
                    KRW: "₩", // South Korean Won
                    NGN: "₦", // Nigerian Naira
                    PHP: "₱", // Philippine Peso
                    PLN: "zł", // Polish Zloty
                    PYG: "₲", // Paraguayan Guarani
                    THB: "฿", // Thai Baht
                    UAH: "₴", // Ukrainian Hryvnia
                    VND: "₫", // Vietnamese Dong
                };
                return currency_symbols[currency.toUpperCase()];
            };
        },
        iconPayment() {
            return (data) => "images/payments/methods/" + data + ".png";
        },
    },
    data() {
        return {
            show: false,
            items: [],
            payments: [],
            plan_id: null,
        };
    },
    methods: {
        fetchPlans() {
            this.$store
                .dispatch("fetchPlans")
                .then((res) => (this.items = res.data))
                .catch((err) => console.log(err));
        },
        fetchPayments() {
            this.$store
                .dispatch("fetchPaymentMethods")
                .then((res) => (this.payments = res.data.data))
                .catch((err) => console.log(err));
        },
        onPayment(id) {
            this.$router.push({
                name: "payment-summary",
                params: { plan_id: this.plan_id, payment_id: id },
            });
        },
        onPurchase(id) {
            this.show = true;
            this.plan_id = id;
        },
    },
};
</script>

<style lang="scss">
.pricing-free-trial {
    margin-top: 2.5rem !important;
}
.payment-icon {
    float: left;
}

@import "@core/scss/vue/pages/page-pricing.scss";
</style>
